<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">好友申请</div>
    </div>
    <div class="backColor">
      <div class="information">
        <div class="hear">
          <img :src="user.headImg">
        </div>
        <div class="name">{{ user.nickName }}</div>
        <div class="school">{{ user.school }}</div>
        <div class="timeBox">
          <div>申请时间</div>
          <div>{{ user.time }}</div>
        </div>
        <div class="reason">申请理由</div>
        <div class="reasonContent">
<!--          华盛顿大学于1861年11月4日正式成立，最初被命名为华盛顿领地大学-->
          {{ user.wording }}
        </div>
      </div>
      <div class="notesBox">
        <img src="../../image/quanz/x1.png" alt="" />
        <input type="text" placeholder="设置备注昵称" v-model="remark"/>
      </div>
      <div class="withBtn" @click="pass()" v-if="user.type === 'Pendency_Type_ComeIn'">通过验证</div>
      <div class="footerBtn" v-if="user.type === 'Pendency_Type_ComeIn'">
        <div @click="showPopup()">
          <img src="../../image/quanz/yzgd.png" alt="" />
        </div>
        <div @click="refuse()">拒绝</div>
      </div>
    </div>
    <van-popup v-model="show" round position="bottom">
      <div class="popupDiv" @click="lhEveent()">拉黑</div>
<!--      <div class="popupDiv">举报/投诉</div>-->
      <div class="line"></div>
      <div class="popupDiv1" @click="show = false">取消</div>
    </van-popup>
    <van-overlay :show="showOver" @click="showOver = false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="blockTop">将“{{ user.nickName }}”移除黑名单</div>
          <div class="blockContent">
            移除后对方将可以关注你、评论或点赞你的圈子、向你发私信等。
          </div>
          <div class="blockBtn">
            <div @click="showOver = false">取消</div>
            <div @click="removeEvent()">确定</div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      showOver: false,
      info: '',
      userId: '',  // 当前显示用户id
      remark: '',
      user: {
        headImg: "",
        nickName: "",
        school: "",
        address: "",
        desc: "",
        time: '',
        wording: '',
        type: '',
      },
    };
  },
  mounted() {
    let detail = JSON.parse(this.$route.query.detail);
    this.user.headImg = detail.fromHeadImg;
    this.user.time = detail.time;
    this.user.nickName = detail.fromName;
    this.user.wording = detail.msg;
    this.user.type = detail.type;
    this.userId = detail.fromId;
    let user = this.$store.state.user;
    if (user.circleAuth == 2) {
      if (!this.tim.isReady()) {
        // SDK 进入 ready 状态时触发，接入侧监听此事件，然后可调用 SDK 发送消息等 API，使用 SDK 的各项功能。
        let onSdkReady = function(event) {
          this.getUser();
        };
        this.tim.on(this.Tim.EVENT.SDK_READY, onSdkReady, this);
        //SDK 进入 not ready 状态时触发，此时接入侧将无法使用 SDK 发送消息等功能。如果想恢复使用，接入侧需调用 login 接口，驱动 SDK 进入 ready 状态。
        let onSdkNotReady = function(event) {
          this.imLogin();
        };
        this.tim.on(this.Tim.EVENT.SDK_NOT_READY, onSdkNotReady, this);
        this.imLogin();
      } else {
        this.getUser();
      }
    }
  },
  destroyed() {
    this.tim.logout();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    // 更多
    showPopup() {
      this.show = true;
    },
    // 拉黑弹框
    lhEveent() {
      this.showOver = true;
      this.show = false;
    },
    // 确定拉黑
    removeEvent() {
      this.show = false;
      this.addBlack();
    },
    // 查看用户信息
    getUser() {
      let promise = this.tim.getUserProfile({
        userIDList: [this.userId + ""] // 请注意：即使只拉取一个用户的资料，也需要用数组类型，例如：userIDList: ['user1']
      });
      let tis = this;
      promise.then(function(imResponse) {
        // console.log("userProfile", imResponse)
        let userProfile = imResponse.data[0];
        // tis.user.nickName = userProfile.nick;
        // tis.user.address = userProfile.location;
        // tis.user.desc = userProfile.selfSignature;
        // tis.user.headImg = userProfile.avatar;
        let profileCustom = userProfile.profileCustomField;
        let schoolJoinTime;
        for (let index in profileCustom) {
          if (profileCustom[index].key === "Tag_Profile_Custom_School") {
            tis.user.school = profileCustom[index].value;
          }
          if (profileCustom[index].key === "Tag_Profile_Custom_STime") {
            schoolJoinTime = profileCustom[index].value;
          }
        }
      }).catch(function(imError) {
        console.warn('getUserProfile error:', imError); // 获取其他用户资料失败的相关信息
      });
    },
    imLogin() {
      let user = this.$store.state.user;
      let timReady = sessionStorage.getItem("timReady");
      if (user.circleAuth == 2 && timReady !== "true") {
        let loginPromise = this.tim.login({userID: user.id + "", userSig: user.imSig});
        loginPromise.then(function (imResponse) {
          if (imResponse.data.repeatLogin === true) {
            // 标识账号已登录，本次登录操作为重复登录。
            console.log(imResponse.data.errorInfo);
          }
        }).catch(function (imError) {
          console.warn('login error:', imError); // 登录失败的相关信息
        });
      }
    },
    // 同意好友申请
    pass() {
      let promise = this.tim.acceptFriendApplication({
        userID: this.userId + "",
        remark: this.remark,
        type: this.Tim.TYPES.SNS_APPLICATION_AGREE_AND_ADD
      });
      promise.then(function(imResponse) {
        // 同意好友成功后，SDK 会触发 TencentCloudChat.EVENT.FRIEND_APPLICATION_LIST_UPDATED 事件
      }).catch(function(imError) {
        console.warn('acceptFriendApplication error:', imError);
      });
    },
    // 拒绝好友申请
    refuse() {
      let promise = this.tim.refuseFriendApplication({
        userID: this.userId + "",
      });
      promise.then(function(imResponse) {
        // 拒绝成功后，SDK 会触发 TencentCloudChat.EVENT.FRIEND_APPLICATION_LIST_UPDATED 事件
      }).catch(function(imError) {
        console.warn('refuseFriendApplication error:', imError);
      });
    },
    // // 删除好友申请
    // deleteFriendApply(userID) {
    //   let promise = this.tim.refuseFriendApplication({
    //     userID: userID,
    //   });
    //   promise.then(function(imResponse) {
    //     // 拒绝成功后，SDK 会触发 TencentCloudChat.EVENT.FRIEND_APPLICATION_LIST_UPDATED 事件
    //   }).catch(function(imError) {
    //     console.warn('refuseFriendApplication error:', imError);
    //   });
    // },
    addBlack() {
      let userID = this.userId + "";
      // 请注意：即使只添加一个用户账号到黑名单，也需要用数组类型，例如：userIDList: ['user1']
      let promise = this.tim.addToBlacklist({userIDList: [userID]});
      promise.then(function(imResponse) {
        console.log(imResponse.data); // 成功添加到黑名单的账号信息，结构为包含用户 userID 的数组 - [userID]
      }).catch(function(imError) {
        console.warn('addToBlacklist error:', imError); // 添加用户到黑名单列表失败的相关信息
      });

    }
  },
};
</script>

<style scoped>
.boxTop {
  display: flex;
  align-items: center;
  margin-top: 34px;
  padding-bottom: 18px;
  box-sizing: border-box;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-left: 232px;
}
.backColor {
  width: 100%;
  height: 100vh;
  background: #f6f8fa;
  overflow: hidden;
}
.information {
  width: 686px;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
  opacity: 1;
  margin-top: 80px;
  margin-left: 32px;
  position: relative;
}
.hear {
  width: 120px;
  height: 120px;
  //background: red;
  border-radius: 50%;
  position: absolute;
  top: -40px;
  left: 283px;
  overflow: hidden;
}
.hear img{
  height: 100%;
}
.name {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  padding-top: 96px;
  box-sizing: border-box;
}
.school {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  text-align: center;
  margin-top: 12px;
}
.timeBox {
  width: 686px;
  height: 104px;
  border-top: 1px solid #f6f8fa;
  border-bottom: 1px solid #f6f8fa;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.timeBox div:nth-child(1) {
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
  margin-left: 32px;
}
.timeBox div:nth-child(2) {
  font-weight: 400;
  color: #0b1526;
  font-size: 28px;
  margin-right: 32px;
}
.reason {
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
  margin-left: 32px;
  margin-top: 32px;
}
.reasonContent {
  width: 622px;
  font-size: 28px;
  font-weight: 400;
  color: #0b1526;
  margin-left: 32px;
  margin-top: 12px;
  padding-bottom: 34px;
}
.notesBox {
  width: 686px;
  height: 104px;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
  opacity: 1;
  margin-left: 32px;
  margin-top: 32px;
  display: flex;
  align-items: center;
}

.notesBox img {
  width: 32px;
  height: 32px;
  margin-left: 32px;
}
.notesBox input {
  width: 300px;
  height: 100px;
  outline: none;
  border: none;
  font-weight: 400;
  font-size: 28px;
  margin-left: 8px;
}
.withBtn {
  width: 684px;
  height: 84px;
  background: #0957c3;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  font-size: 32px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 84px;
  margin-left: 32px;
  margin-top: 64px;
}
.footerBtn {
  display: flex;
  margin-left: 32px;
  margin-top: 32px;
}
.footerBtn div:nth-child(1) img {
  width: 200px;
  height: 84px;
}
.footerBtn div:nth-child(2) {
  width: 456px;
  height: 84px;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  border: 2px solid #f44336;
  font-size: 32px;
  font-weight: 400;
  color: #f44336;
  text-align: center;
  line-height: 84px;
  margin-left: 24px;
}
.popupDiv,
.popupDiv1 {
  width: 750px;
  height: 104px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  font-size: 32px;
  font-weight: 400;
  color: #0b1526;
  text-align: center;
  line-height: 104px;
  border-bottom: 1px solid #f6f8fa;
}
.popupDiv1 {
  border: none;
}
.line {
  width: 750px;
  height: 20px;
  background: #f6f8fa;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  width: 510px;
  height: 346px;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
  opacity: 1;
}
.blockTop {
  width: 510px;
  height: 88px;
  border-bottom: 1px solid #f6f8fa;
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  text-align: center;
  line-height: 88px;
}
.blockContent {
  font-size: 28px;
  width: 446px;
  font-weight: 400;
  color: #0b1526;
  margin-left: 32px;
  margin-top: 20px;
}
.blockBtn {
  display: flex;
  margin-top: 32px;
}
.blockBtn div:nth-child(1) {
  width: 160px;
  height: 84px;
  background: #f6f8fa;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  font-size: 32px;
  font-weight: 400;
  color: #0b1526;
  text-align: center;
  line-height: 84px;
  margin-left: 32px;
}
.blockBtn div:nth-child(2) {
  width: 262px;
  height: 84px;
  background: #0957c3;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  font-size: 32px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 84px;
  margin-left: 24px;
}
</style>
